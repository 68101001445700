.vote-submit-card {
  display: flex; /* This can remain, but we'll adjust to ensure vertical layout */
  flex-direction: column; /* Change to column to stack items vertically */
  background-color: #ffffff;
  width: 100%;
}

.vote-submit-items {
  display: flex;
  align-items: flex-start; /* Change this from center to flex-start */
  justify-content: space-between;
  background-color: #fff;
  padding: 15px 10px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #ccc; 
}

.input-label {
  display: block;
}

.opinion-submit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5px;
}

.opinion-submit-buttons {
  display: flex;
  flex-direction: row;
  gap: .7vw .8vw; 
}

.opinion-submit-buttons button {
  flex: 1; 
  min-width: 0; 
  padding: .8vw .9vw; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); 
}

.comment-submit-input {
  width: 100%; 
  padding: 10px; 
  border-radius: 4px; 
  border: 1px solid #ccc; 
  resize: vertical; 
  height: 70px; 
  font-family: inherit; 
  font-size: 1rem; 
  line-height: 1.5; 
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); 
  transition: border-color 0.3s ease, box-shadow 0.3s ease; 
}

.comment-submit-container {
  min-width: 200px;
  width: 550px;
}

.comment-submit-input:focus {
  border-color: var(--focus-border-color);
  box-shadow: var(--focus-box-shadow);
  outline: var(--focus-outline);
}


.action-submit-container {
  width: 85px; /* Define the width of the container */
  display: flex;
  padding-top: 25px;
}


@media (max-width: 868px) {
  .vote-submit-items {
      flex-direction: column;
      align-items: flex-start;
  }

  .input-label {
    display: none;
  }
  
  .opinion-submit-buttons {
    display: flex;
    flex-direction: row;
}

.opinion-submit-buttons button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); 
}


  .opinion-submit-container,
  .comment-submit-container,
  .action-submit-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-top: 10px; /* Adds space between stacked items */
  }
}
  