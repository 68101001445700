/*************** Styles for Proposal Form Component ***************/
.form-container {
  border-radius: 8px;
  border: 1px solid #c5c5c5;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  max-width: 95%; /* Adjusted for smaller screens */
  width: 90%; /* Relative to viewport for better responsiveness */
  min-width: 300px; /* Slightly adjusted to maintain minimum readability */
  margin: 0 auto;
}

  .proposal-form {
    background-color: lightgray;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .proposal-form label {
    display: block;
    padding: 5px;
  }
  
  .proposal-form input[type="text"],
  .proposal-form input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .proposal-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .error {
    color: red;
    margin-top: 5px; /* Ensure some space between the editor and the error message */
  }
  
  .quill-editor {
    width: 100%;
    height: 35vh;
    max-height: 40vh;
    min-height: 20vh;
    overflow-y:hidden; 
    background-color: #ffffff;
    border: 1px solid #ccc; 
    border-radius: 5px; 
    box-sizing: border-box; 
  }


  @media (min-width: 1024px) {
    .form-container {
        max-width: 950px; /* Maximum width when there's enough space */
        width: 80%; /* Maintain relative sizing */
    }
}
  