.description-card {
    background-color: #ffffff;
}

.description-card p {
  font-size: 14px;
}

.example-alert {
    color: #c09853;
    padding: 8px 30px 8px 14px;
    margin-bottom: 5px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
  }
  
  .first-render-message {   
    padding: 15px;
    margin-bottom: 20px;
    background-color: rgba(144, 238, 144, 0.8); 
    color: #333; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    font-size: 13px; 
  }

  .proposal-header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }

  .settings-dropdown {
    margin-left: auto; 
  }

  .settings-dropdown .faEllipsis {
    cursor: pointer;
    font-size: 1.2rem;
    color: #999;
    transition: color 0.3s ease;
  }
  
  .settings-dropdown:hover .faEllipsis {
    color: #333; 
  }

  .dropdown-item.disabled {
    color: #ccc; 
    cursor: not-allowed;
    pointer-events: none; 
    background-color: #f0f0f0; 
  }

  .proposal-description {
    background-color: rgba(228, 228, 228, 0.4);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .three-column-layout {
    display: grid;
    align-items: flex-start;
    grid-template-columns: .6fr .63fr 1.5fr; /* Responsive middle column */
    width: 100%;
    height: 100%;
}

  
  .opinion-tally-container {
    display: flex;
    justify-content: space-between; /* Evenly space the items */
    padding: 17px;
    max-width: 430px;
    min-width: 311px;
    box-sizing: border-box; /* Include padding in width */
  }
  
  .opinion-content {
    text-align: center; /* Optional: centers content horizontally */   
  }
  
  .opinion-tally-item {
    display: flex;
    align-items: center;
    justify-content: center;    
  }
  
  .opinion-count {
    margin-left: 5px;   
  }



  /* Mobile view (max-width: 768px for tablet, adjust as needed) */
  @media (max-width: 868px) {
  .three-column-layout {
    display: block; /* Stack the columns vertically */
    width: 80%; /* Make it take up the full width */
    margin: 0 auto;
  }
  
  .left-column, .middle-column, .right-column {
    width: 100%; /* Each section takes up the full width */
    margin-bottom: 20px; /* Add some space between stacked sections */
  }

  .opinion-tally-container {
    max-width: 100%; /* Take up the full width on mobile */
    min-width: unset; /* Remove the minimum width */
  }
}

  
  
  
  

