.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-card, .basics-card {
  width: 80%;
  max-width: 700px;
  background-color: lightgray;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-bottom: 40px;
}

.button-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  color: white;
  text-align: center;
}

.button.success {
  background-color: green;
}

.button.primary {
  background-color: blue;
}

.features-section, .pricing-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.feature, .pricing-card {
  width: 45%;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.teams-section, .pay-section {
  display: flex;
  width: 100%;
  height: 60vh;
  background-color: #f3d361;
  margin-bottom: 40px;
}

.teams-box, .pay-box {
  width: 50%;
  padding: 20px;
  overflow: auto;
}

.image-box {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
