:root {
  --primary-color: #007bff;
  --secondary-color: #f9f9f9;
  --dark-bg: #1b1b1b;
  --light-bg: lightgray;
  --font-family: Arial, sans-serif;
  --text-color: #ececec;
  --secondary-text-color:#1b1b1b ;
  --border-color: #ccc;
  --focus-border-color: #007BFF;
  --focus-box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  --focus-outline: none;
 }
 
 *,
 *::before,
 *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
 }
 
 body {
  height: 100%;
  display: flex;
  flex-direction: column;
 }
 
 
 
 
 /**************************** Global Container Styles *******************************/
 .page-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  flex-wrap: wrap;
 }
 
 .component-container-1,
 .component-container-2 {
  border: 1px solid lightblue;
  border-radius: 5px;
  padding: 15px;
  flex: 1;
  min-width: 380px;
  box-shadow: .1rem .1rem .2rem rgba(0, 0, 0, 0.5);
 }
 
 .cardlist-item {
  width: 90%;
  max-width: 480px;
  min-height: 180px;
  box-sizing: border-box;
  margin: 0;
  padding: 20px;
  border-radius: 10px;
  background-color: #dddbdb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
 }
 
 @media (max-width: 950px) {
  .cardlist-item {
    width: 100%;
  }
 }
 
 
 
 
 
 
 /***************************** Global Button Styles **********************************/
 .small-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 3px;
  min-width: 60px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
 }
 
 .small-delete-button {
  background-color: transparent; /* Make the background transparent */
  color: red; /* Set the text color to red */
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 5px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
 }
 
 .small-button:hover {
  background-color: #4e6f92;
 }
 
 .medium-button {
  background-color: #3b8adfef;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  margin-left: 3px;
  min-width: 100px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
 }
 
 .medium-button:hover {
  background-color: #547191;
 }
 
 
 
 /**************************** Global Input Styles **********************************/
 .input-field {
  border-radius: 4px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
 }

 .input-field:focus {
  border-color: var(--focus-border-color);
  box-shadow: var(--focus-box-shadow);
  outline: var(--focus-outline);
 }
 
 /* Style for red outline on error */
.input-error {
  border: 1px solid red;
}

/* Style for error message text */
.error-text {
  color: red;
  font-size: 0.875em;
  margin-top: 4px;
}
 
 
 
 
 
 /**************************** Global Tooltip Styles **********************************/
 .tooltip {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
 }
 
 .tooltip h3 {
  margin-top: 0;
 }
 
 .tooltip p {
  margin-bottom: 0.5rem;
 }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 