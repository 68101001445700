/**************Styles for User Dashboard****************/
.user-details {
    width: fit-content; 
    padding: 10px; 
    margin: 0 auto;
    background-color: #f0f0f0; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border: 1px solid #ccc; 
    align-self: start; 
    text-align: center;
    box-sizing: border-box; 
  }
  
  .proposal-button-group {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    flex-wrap: wrap; 
    gap: 2px; 
  }
  
  .confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
  }
  
  .confirmation-content {
    text-align: center;
  }
  
  .confirmation-options label {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .confirmation-options input[type="radio"] {
    margin-right: 5px;
  }
  
  .participated-proposal-filter-options {
    display: flex;
    align-items: center;
  
  }
  
  .participated-proposal-filter-options .form-check {
    margin-right: 20px; 
  }
  
  .user-proposal-filter-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
  }
  
  
  
  