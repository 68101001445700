.proposal-vote-page-container {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: flex-start; 
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  padding: 5px;
}

.description-card {
  width: 100%;
  padding: 8px;
}



  


  
