/* Dropdown Trigger (icon) */
.dropdown-trigger {
  position: relative;
  cursor: pointer;
  display: inline-block;
  outline: none;
}

.dropdown-icon {
  font-size: 1.5rem;
  color: #999;
  transition: color 0.3s ease;
}

.dropdown-trigger:hover .dropdown-icon {
  color: #333; /* On hover */
}

/* Dropdown Menu */
.custom-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0; /* Align to the right of the icon */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  width: 200px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
}

.custom-dropdown-menu.active {
  display: block;
  opacity: 1;
  visibility: visible;
}

.dropdown-item {
  padding: 10px 20px;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
  transition: background-color 0.5s ease;
  word-wrap: break-word;
  white-space: normal; /* This allows wrapping */
}

.dropdown-item:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #eaeaea; /* Divider between items */
}

/* Default dropdown position - right aligned */
.dropdown-right .custom-dropdown-menu {
  right: 0; /* Align to the right of the icon */
  left: auto;
  
}

/* If you want to align the dropdown to the left instead */
.dropdown-left .custom-dropdown-menu {
  left: 0;
  right: auto;
}

/* If you want to center the dropdown below the icon */
.dropdown-center .custom-dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}

.header-dropdown-position {
  right: 10px;
}


